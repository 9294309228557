<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                    label="Kod"
                    horizontal
                    v-on:keyup.enter="filter"
                    placeholder="KAFT1000"
                    v-model="data.code"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                    label="Tarih"
                    horizontal
                    style="width: 50%"
                    type="date"
                    v-model="data.startTime"
                />
                <CInput
                    label=" "
                    horizontal
                    style="width: 50%"
                    type="date"
                    v-model="data.endTime"
                />
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "PromotionFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
       methods:{
            getCities: async function(){
                let params = {countryId: this.data.countryId}
                await this.$store.dispatch('allCities_list', params)
            },
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>